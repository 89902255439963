import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import { endPoints } from 'utils/endPoints'
export const subscriptionApi=createApi({
reducerPath:"subscriptionApi",
baseQuery:fetchBaseQuery({baseUrl:"https://partyfixersapi.iosx.in/api/v1/"}),
endpoints:(builder)=>({
    getUserSubscription:builder.query({
        query:(userId)=>`${endPoints.subscription}/list?userId=${userId}`
    })
})
});
export const {usetGetUserSubscriptionQuery} = subscriptionApi