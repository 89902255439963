import React, { useEffect } from 'react';
import { onBlankLayout } from 'store/slices/themeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { loadSettingDetails } from 'services/appServices/siteSettingService';
import { getSettings } from 'store/slices/settingSlice';

const AppRoute = ({ component: Component, routeKey, blankLayout, ...props }) => {

	const dispatch = useDispatch()
	const settings = useSelector((state) => state?.settings?.data);
	useEffect(() => {
		const isBlank = blankLayout ? true : false
		dispatch(onBlankLayout(isBlank))

	}, [blankLayout])
	useEffect(() => {
		loadSettingDetails().then((res)=>{
			
			dispatch(getSettings(res?.data))
		});
	  }, []);
	  function favIconChange() {
		let link = document.querySelector("link[rel~='icon']");
		if (!link) {
		  link = document.createElement("link");
		  link.rel = "icon";
		  document.getElementsByTagName("head")[0].appendChild(link);
		}
		link.href = settings?.favicon?.url;
	  }
	  favIconChange()
	return (
		<Component {...props} />
	)
}

export default AppRoute