import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import settings from './slices/settingSlice'
import { subscriptionApi } from './slices/api'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        settings,
        // [subscriptionApi.reducerPath]:subscriptionApi.reducerPath,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
