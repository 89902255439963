import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "login-1",
    path: `${AUTH_PREFIX_PATH}/login-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-1")
    ),
  },
  {
    key: "login-2",
    path: `${AUTH_PREFIX_PATH}/login-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-2")
    ),
  },
  {
    key: "register-1",
    path: `${AUTH_PREFIX_PATH}/register-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-1")
    ),
  },
  {
    key: "register-2",
    path: `${AUTH_PREFIX_PATH}/register-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-2")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "error-page-1",
    path: `${AUTH_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
  },
  {
    key: "error-page-2",
    path: `${AUTH_PREFIX_PATH}/error-page-2`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-2")),
  },
];

export const protectedRoutes = [
  {
    key: "admins.list",
    path: `${APP_PREFIX_PATH}/admins/list`,
    component: React.lazy(() => import("views/app-views/apps/admin/adminList")),
  },
  {
    key: "admins.add",
    path: `${APP_PREFIX_PATH}/admins/add`,
    component: React.lazy(() => import("views/app-views/apps/admin/adminAdd")),
  },
  {
    key: "profile.password",
    path: `${APP_PREFIX_PATH}/change-password`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/ChangePassword")
    ),
  },
  {
    key: "admins.add",
    path: `${APP_PREFIX_PATH}/admins/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/admin/admin-edit")
    ),
  },
  {
    key: "admins.details",
    path: `${APP_PREFIX_PATH}/admin/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/admin/AdminDetails")
    ),
  },
  {
    key: "users.list",
    path: `${APP_PREFIX_PATH}/users/list`,
    component: React.lazy(() => import("views/app-views/apps/user/userList")),
  },
  {
    key: "users.add",
    path: `${APP_PREFIX_PATH}/user/add`,
    component: React.lazy(() => import("views/app-views/apps/user/userAdd")),
  },
  {
    key: "users.edit",
    path: `${APP_PREFIX_PATH}/user/edit/:id`,
    component: React.lazy(() => import("views/app-views/apps/user/userEdit")),
  },
  {
    key: "users.details",
    path: `${APP_PREFIX_PATH}/user/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/user/UserDetails")
    ),
  },
  {
    key: "vendors.list",
    path: `${APP_PREFIX_PATH}/vendors/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/vendor/vendorList")
    ),
  },
  {
    key: "vendors.add",
    path: `${APP_PREFIX_PATH}/vendor/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/vendor/vendorAdd")
    ),
  },
  {
    key: "vendors.edit",
    path: `${APP_PREFIX_PATH}/vendor/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/vendor/vendorEdit")
    ),
  },
  {
    key: "vendors.details",
    path: `${APP_PREFIX_PATH}/vendor/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/vendor/VendorDetails")
    ),
  },
  {
    key: "company.list",
    path: `${APP_PREFIX_PATH}/company/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/user/editCompany")
    ),
  },

  {
    key: "pages",
    path: `${APP_PREFIX_PATH}/pages`,
    component: React.lazy(() => import("views/app-views/pages")),
  },
  {
    key: "pages.profile",
    path: `${APP_PREFIX_PATH}/pages/profile`,
    component: React.lazy(() => import("views/app-views/pages/profile")),
  },
  {
    key: "pages.invoice",
    path: `${APP_PREFIX_PATH}/pages/invoice`,
    component: React.lazy(() => import("views/app-views/pages/invoice")),
  },
  {
    key: "pages.setting",
    path: `${APP_PREFIX_PATH}/pages/setting/*`,
    component: React.lazy(() => import("views/app-views/pages/setting")),
  },
  {
    key: "pages.user-list",
    path: `${APP_PREFIX_PATH}/pages/user-list`,
    component: React.lazy(() => import("views/app-views/pages/user-list")),
  },

  {
    key: "dashboard.visit-dashboard",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() =>
      import("views/app-views/apps/dashboard/view-dashboard/")
    ),
  },
  // Post section
  {
    key: "post-category",
    path: `${APP_PREFIX_PATH}/post-category/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/post/category/categoryList")
    ),
  },
  {
    key: "post-category.add-post-category",
    path: `${APP_PREFIX_PATH}/post-category/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/post/category/add-categoryList")
    ),
  },
  {
    key: "post-category.edit-post-category",
    path: `${APP_PREFIX_PATH}/post-category/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/post/category/edit-categoryList")
    ),
  },
  {
    key: "post-category.postlist",
    path: `${APP_PREFIX_PATH}/posts/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/post/Posts/PostList")
    ),
  },
  {
    key: "post-category.postlist",
    path: `${APP_PREFIX_PATH}/posts/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/post/Posts/Add-Post")
    ),
  },
  {
    key: "post-category.postedit",
    path: `${APP_PREFIX_PATH}/posts/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/post/Posts/Edit-post")
    ),
  },
  {
    key: "post-category.postlist",
    path: `${APP_PREFIX_PATH}/posts/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/post/Posts/PostDetails")
    ),
  },
  // Post comments
  {
    key: "post-category.commentlist",
    path: `${APP_PREFIX_PATH}/comments/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/post/postComments/PostCommentList")
    ),
  },

  //Tags
  // {
  //   key: "post-category.postlist",
  //   path: `${APP_PREFIX_PATH}/tags/list`,
  //   component: React.lazy(() =>
  //     import("views/app-views/apps/post/Tags/TagList")
  //   ),
  // },

  // job category
  {
    key: "job-category.category",
    path: `${APP_PREFIX_PATH}/job-category/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/JobField/JobCategory/JobList")
    ),
  },
  {
    key: "job-category.app-category",
    path: `${APP_PREFIX_PATH}/job-category/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/JobField/JobCategory/Add-job-category")
    ),
  },
  {
    key: "job-category.app-category",
    path: `${APP_PREFIX_PATH}/job-category/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/JobField/JobCategory/Edit-job-category")
    ),
  },

  // jobtypes

  {
    key: "job-category.job",
    path: `${APP_PREFIX_PATH}/job-types/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/JobField/JobType/job-type-list")
    ),
  },
  {
    key: "job-category.add-job-types",
    path: `${APP_PREFIX_PATH}/job-types/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/JobField/JobType/job-type-add")
    ),
  },
  {
    key: "job-category.Edit-job-types",
    path: `${APP_PREFIX_PATH}/job-types/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/JobField/JobType/job-type-edit")
    ),
  },
  //job-specializations
  {
    key: "jobtree.job-specializations",
    path: `${APP_PREFIX_PATH}/job-specializations/list`,
    component: React.lazy(() =>
      import(
        "views/app-views/apps/JobField/Job-Specialization/Specialization-list"
      )
    ),
  },
  {
    key: "jobtree.job-specializations",
    path: `${APP_PREFIX_PATH}/job-specialization/add`,
    component: React.lazy(() =>
      import(
        "views/app-views/apps/JobField/Job-Specialization/Specialization-Add"
      )
    ),
  },
  {
    key: "jobtree.job-specializations",
    path: `${APP_PREFIX_PATH}/job-specialization/edit/:id`,
    component: React.lazy(() =>
      import(
        "views/app-views/apps/JobField/Job-Specialization/Specialization-Edit"
      )
    ),
  },

  // Jobs
  {
    key: "job-category.job",
    path: `${APP_PREFIX_PATH}/job/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/JobField/Job/JobmainList")
    ),
  },
  {
    key: "job-category.add-job",
    path: `${APP_PREFIX_PATH}/job/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/JobField/Job/add-job")
    ),
  },
  {
    key: "job-category.add-job",
    path: `${APP_PREFIX_PATH}/job/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/JobField/Job/edit-job")
    ),
  },
  {
    key: "job-category.add-job",
    path: `${APP_PREFIX_PATH}/job/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/JobField/Job/jobDetails")
    ),
  },
  // Job Application
  {
    key: "job-category.job",
    path: `${APP_PREFIX_PATH}/job/applications/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/JobField/Applications/List")
    ),
  },
  {
    key: "job-category.job",
    path: `${APP_PREFIX_PATH}/job/application/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/JobField/ApplicationDetails")
    ),
  },

  //---------------------------Booking----------------------------------------
  {
    key: "booking.list",
    path: `${APP_PREFIX_PATH}/booking/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/booking/BookingList/index")
    ),
  },
  {
    key: "booking.booking",
    path: `${APP_PREFIX_PATH}/booking/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/booking/Add-Booking")
    ),
  },
  {
    key: "booking.booking",
    path: `${APP_PREFIX_PATH}/booking/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/booking/Edit-Booking")
    ),
  },
  {
    key: "partyRole.list",
    path: `${APP_PREFIX_PATH}/partyRole/list`,
    component: React.lazy(() => import("views/app-views/apps/partyRole/index")),
  },

  //---------------------------info----------------------------------------
  {
    key: "info.list",
    path: `${APP_PREFIX_PATH}/info/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/info/infoList/index")
    ),
  },
  {
    key: "info.info",
    path: `${APP_PREFIX_PATH}/info/add`,
    component: React.lazy(() => import("views/app-views/apps/info/Add-Info")),
  },
  {
    key: "info.info",
    path: `${APP_PREFIX_PATH}/info/edit/:id`,
    component: React.lazy(() => import("views/app-views/apps/info/Edit-Info")),
  },
  //---------------------------Pages----------------------------------------
  {
    key: "pages.list",
    path: `${APP_PREFIX_PATH}/pages/list`,
    component: React.lazy(() => import("views/app-views/apps/Pages/PagesList")),
  },
  // {
  //   key: "pages.list",
  //   path: `${APP_PREFIX_PATH}/page/add`,
  //   component: React.lazy(() => import("views/app-views/apps/Pages/PagesAdd")),
  // },
  {
    key: "pages.list",
    path: `${APP_PREFIX_PATH}/page/edit/:id`,
    component: React.lazy(() => import("views/app-views/apps/Pages/PagesEdit")),
  },
  //Email TEmplates

  {
    key: "EmailTemplate.list",
    path: `${APP_PREFIX_PATH}/email-templates/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/Email-Templates/EmailTemplateList")
    ),
  },
  {
    key: "EmailTemplate.add",
    path: `${APP_PREFIX_PATH}/email-template/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/Email-Templates/EmailTemplateAdd")
    ),
  },
  {
    key: "EmailTemplate.edit",
    path: `${APP_PREFIX_PATH}/email-template/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/Email-Templates/EmailTemplateEdit")
    ),
  },
  //----------------------------F A Q------------------------------------------
  {
    key: "Faq.list",
    path: `${APP_PREFIX_PATH}/faq/list`,
    component: React.lazy(() => import("views/app-views/apps/faq/list/index")),
  },
  {
    key: "Faq.Faq",
    path: `${APP_PREFIX_PATH}/faq/add`,
    component: React.lazy(() => import("views/app-views/apps/faq/AddFaq")),
  },
  {
    key: "Faq.Faq",
    path: `${APP_PREFIX_PATH}/faq/edit/:id`,
    component: React.lazy(() => import("views/app-views/apps/faq/EditFaq")),
  },

  //----------------------------S E R V I C E S------------------------------------------

  {
    key: "service-cgategory.category",
    path: `${APP_PREFIX_PATH}/service-category/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/ServiceCategory/addCategory")
    ),
  },
  {
    key: "service-cgategory.category",
    path: `${APP_PREFIX_PATH}/service-category/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/ServiceCategory/editCategory")
    ),
  },
  {
    key: "service-cgategory.category",
    path: `${APP_PREFIX_PATH}/service-categories/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/ServiceCategory/listCategory")
    ),
  },

  {
    key: "Service.list",
    path: `${APP_PREFIX_PATH}/service/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/ServiceField/ServiceList")
    ),
  },
  {
    key: "Service.add",
    path: `${APP_PREFIX_PATH}/service/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/ServiceField/ServiceAdd")
    ),
  },
  {
    key: "Service.edit",
    path: `${APP_PREFIX_PATH}/service/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/ServiceField/ServiceEdit")
    ),
  },
  {
    key: "Service.details",
    path: `${APP_PREFIX_PATH}/service/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/ServiceField/ServiceDetails")
    ),
  },

    //---------------------------enquiry----------------------------------------
    {
      key: "enquiry.list",
      path: `${APP_PREFIX_PATH}/enquiry/list`,
      component: React.lazy(() =>
        import("views/app-views/apps/enquiry/EnquiryList/index")
      ),
    },
    {
      key: "complain.list",
      path: `${APP_PREFIX_PATH}/complain/list`,
      component: React.lazy(() =>
        import("views/app-views/apps/complain/ComplainList/index")
      ),
    },
  //----------------------------R E V I E W------------------------------------------
  {
    key: "Review.list",
    path: `${APP_PREFIX_PATH}/review/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/review/ReviewList")
    ),
  },
  {
    key: "Review.add",
    path: `${APP_PREFIX_PATH}/review/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/review/ReviewAdd")
    ),
  },
  {
    key: "Review.edit",
    path: `${APP_PREFIX_PATH}/review/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/review/ReviewEdit")
    ),
  },
  // Site Setting
  {
    key: "site.settings.list",
    path: `${APP_PREFIX_PATH}/site-settings`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/SiteSetting")
    ),
  },
  // Plans=====>>>
  {
    key: "Plans.list",
    path: `${APP_PREFIX_PATH}/plans/list`,
    component: React.lazy(() => import("views/app-views/apps/Plans/Plan-list")),
  },
  {
    key: "Plans.add",
    path: `${APP_PREFIX_PATH}/plans/add`,
    component: React.lazy(() => import("views/app-views/apps/Plans/Plan-add")),
  },
  {
    key: "Plans.edit",
    path: `${APP_PREFIX_PATH}/plans/edit/:id`,
    component: React.lazy(() => import("views/app-views/apps/Plans/Plan-edit")),
  },
  {
    key:"subscriptions.list",
    path: `${APP_PREFIX_PATH}/subscriptions/list`,
    component: React.lazy(() => import("views/app-views/apps/Subscription")),

  }
];
